<template>
  <VCard>
    <VCardTitle>
      All Policies
      <!-- <VBtn class="ml-2" fab x-small color="primary">
        <VIcon small class="">fa fa-plus</VIcon>
      </VBtn> -->
      <VSpacer />
      <VTextField
        v-model="queryPolicy"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        placeholder="longthanh"
        prepend-icon="fa-search"
        hint="Search by email, name or role, ..."
      />
    </VCardTitle>
    <VDataTable
      :headers="headers"
      :items="policies"
      multi-sort
      :loading="tableLoading"
      :search="queryPolicy"
    >
      <template v-slot:[`item.Method`]="{ item }">
        <strong :class="getMethodColor(item.Method)">{{ item.Method }}</strong>
      </template>
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <VBtn class="px-0" fab x-small color="error">
          <VIcon fab x-small class="">fa fa-trash</VIcon>
        </VBtn>
      </template> -->
    </VDataTable>
  </VCard>
</template>

<script>
import SlideInPanel from "@/components/essenstials/SlideInPanel.vue";
import { mapActions, mapState } from "vuex";
import errorHandle from "@/mixins/errorHandle";
import { getMethodColor } from "@/utils/vuetify";

export default {
  name: "ManagePolicy",
  components: { SlideInPanel },
  mixins: [errorHandle()],
  data: () => ({
    panelActive: false,
    tableLoading: false,
    policies: [],
    queryPolicy: "",
  }),
  computed: {
    ...mapState("su", {
      allPolicies: (state) => state.policy.list,
      policyLoading: (state) => state.policy.loading,
    }),
    headers() {
      return [
        { text: "Method", value: "Method", sortable: true, width: "120px" },
        { text: "Path", value: "Path", sortable: true },
        // { text: "Actions", value: "actions", sortable: false },
      ];
    },
  },
  mounted() {
    this.fetchPolicies();
  },
  methods: {
    ...mapActions("su", {
      getPolicies: "fetchPolicies",
    }),
    fetchPolicies() {
      this.tableLoading = true;
      this.getPolicies()
        .then((res) => {
          this.policies = this.allPolicies.map((x) => ({
            ...x,
            ___isEditing: false,
            __loadingRole: false,
          }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    getMethodColor
  },
};
</script>

<style lang="scss" scoped></style>
